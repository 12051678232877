<template>
  <div class="Search">
    <v-container fluid>
      <PageTitle :title-text="$t('Consultas por número de economico')" />
      <v-row>
        <v-col md="4">
          <BaseInputField
            v-model="economico"
            type="text"
            label="Economico"
            @input="computeConceptData()"
          />
        </v-col>
        <v-col md="2">
          <v-btn
            elevation="6"
            :loading="requestEconomico"
            @click="searchEconomico"
          >
            Consultar
          </v-btn>
        </v-col>
      </v-row>

      <!-- <vue-json-to-table :data="resultAwg" /> -->
      <v-row
        v-if="resultAwg.weeks"
        class="d-flex justify-space-around mb-6"
      >
        <!-- <card-stats
          title="Rendimiento técnico"
          :stats="resultAwg.rendimientoTecnico"
        /> -->
        <!-- <card-stats
          title="Rendimiento técnico (AWG)"
          :stats="resultAwg.awgRendimiento"
        /> -->
        <card-stats
          title="Rendimiento técnico"
          :stats="`${resultAwg.awgRendimiento4LastWeek}/${resultAwg.rendimientoTecnico}`"
        />
        <card-stats
          title="KM recorridos"
          :stats="resultAwg.awgKmRecorridos4LastWeek"
        />
        <card-stats
          title="Litros consumidos"
          :stats="resultAwg.awgLitros4LastWeek"
        />
        <card-stats
          title="Litros sugeridos"
          :stats="`${resultAwg.awgLitrosSugeridos}`"
        />
        <card-stats
          title="Saldo sugerido"
          :stats="`$${resultAwg.awgSaldoSugeridos}`"
        />
        <card-stats
          content-class="text-h6"
          title="Semanas"
          :stats=" resultAwg.weeks ? resultAwg.weeks.join(', '): ''"
        />
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :items="resultSerchEconomico"
            :headers="headers"
            :sort-by="['semana']"
            :sort-desc="[true]"
            :no-data-text="$t('commonTable.noDataText')"
            :footer-props="{ itemsPerPageText: $t('commonTable.perPageText') }"
            height="320"
            fixed-header
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import PageTitle from '@/components/PageTitle.vue';
import CardStats from '@/components/CardStats.vue';

import dateFormatMixin from '@/mixins/dateFormat';

import searchService from '../services/modules/search';
import BaseInputField from '@/components/BaseInputField.vue';

export default {
  name: 'Record',
  components: { PageTitle, BaseInputField, CardStats },
  mixins: [dateFormatMixin],
  data() {
    return {
      requestEconomico: false,
      resultSerchEconomico: [],
      resultAwg: {},
      economico: 'SG-06323-0',
      headers: [
        { text: 'semana', value: 'semana' },
        { text: 'economico', value: 'economico' },
        { text: 'rendimientoTecnico', value: 'rendimientoTecnico' },
        { text: 'litrosComprados', value: 'litrosComprados' },
        { text: 'litrosSugeridos', value: 'litrosSugeridos' },
        { text: 'kilometrosRecorridos', value: 'kilometrosRecorridos' },
        { text: 'kilometrosTeoricos', value: 'kilometrosTeoricos' },
        { text: 'rendimientoRealSemanal', value: 'rendimientoRealSemanal' },
        { text: 'indexrendimiento', value: 'indexrendimiento' },
        { text: 'importePagado', value: 'importePagado' },
        { text: 'costoPromedioLitro', value: 'costoPromedioLitro' },
        { text: 'saldoSugerido', value: 'saldoSugerido' },
        { text: 'diferenciaMontos', value: 'diferenciaMontos' },
        { text: 'conductor', value: 'conductor' },
        { text: 'cedis', value: 'cedis' },
      ],
    };
  },
  computed: { ...mapState('record', ['record']) },
  methods: {
    searchEconomico() {
      this.requestEconomico = true;
      searchService.searchEconomic(this.economico)
          .then((response) => {
            this.requestEconomico = false;
            this.resultSerchEconomico = response.data.result;
            this.resultAwg = response.data.awgData;
          })
          .catch((error) => {
            this.requestEconomico = false;
            console.error('FAILURE!!', error);
            this.resultSerchEconomico = error.message;
          });
    },
  },
};
</script>

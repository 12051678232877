<template>
  <div class="PageTitle">
    <v-row>
      <v-col cols="12 text-end">
        <div
          class="text-h4"
        >
          <p
            style="color: var(--v-secondary-lighten2)"
          >
            {{ titleText }}
          </p>
        </div>
        <v-divider />
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'PageTitle',
  props: { titleText: { type: String, default: '' } },
};
</script>

import { apiClient } from '../index';

/**
 * [login description]
 * @param  {[type]} economico    [description]
 * @return {[type]}          [description]
 */
function searchEconomic(economico) {
  return apiClient.post(`/chatbot/search/searchAndCalc?economico=${economico}`,
      {
        template: 'Tu rendimiento en {semana} es de {rendimientoRealSemanal} \
          y el rendimiento tecnico de tu unidad es de {rendimientoTecnico}.',
      },
  );
}

export default { searchEconomic };

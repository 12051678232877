<template>
  <v-text-field
    class="rounded-0"
    v-bind="computedProps"
    v-on="$listeners"
  />
</template>

<script>
import { VTextField } from 'vuetify/lib';

export default {
  name: 'BaseInputField',
  extends: VTextField,

  props: {
    outlined: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    computedProps() {
      return { ...this.$props };
    },
  },
};
</script>

<template>
  <v-card
    elevation="9"
    outlined
    shaped
    max-width="290"
  >
    <v-card-title>
      <v-icon
        large
        left
      >
        mdi-twitter
      </v-icon>
      <span class="text-h6 font-weight-light">{{ title }}</span>
    </v-card-title>
    <v-card-text>
      <v-row align="center">
        <v-col
          :class="contentClass"
          cols="12"
        >
          {{ stats }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CardStats',
  props: {
    contentClass: {
      type: String,
      default: 'text-h5',
    },
    title: {
      type: String,
      default: 'title',
    },
    stats: {
      type: [String, Number],
      default: '0.0',
    },
  },
};
</script>
<style>
.v-sheet.v-card.v-sheet--shaped {
    border-radius: 0;
}
</style>
